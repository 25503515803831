import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';



const useStyles = makeStyles((theme) => ({
  AppBar: {
    backgroundColor: '#661AFF',
  },
  title: {
    flexGrow: 1,
    color: '#F1E7FF',
  },
  b2Text: {
    margin: '0px 8px 3px 0px',
    color: '#F1E7FF',
  },
  linkedInButton: {
    margin: '0px 8px',
    backgroundColor: '#F1E7FF',
    color: '#B893FF',

    "&:hover": {
      backgroundColor: '#F1E7FF',
      color: '#661AFF',
    }
  },
  githubButton: {
    backgroundColor: '#B893FF',
    color: '#661AFF',

    "&:hover": {
      color: 'white',
    }
  },
}));

export default function Header({ title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.AppBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.b2Text}>App By</Typography><Typography variant="body1">Christopher Butler</Typography>
          <Button startIcon={<LinkedInIcon />} className={classes.linkedInButton} variant="contained" href="https://www.linkedin.com/in/christopherharoldbutler/" target="_blank" rel="noreferrer">
            LinkedIn
          </Button>
          <Button startIcon={<GitHubIcon />} className={classes.githubButton} variant="outlined" href="https://github.com/ChristopherHButler" target="_blank" rel="noreferrer">
            Github
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
