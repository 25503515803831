import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Card from './common/Card';
import { sqkmToSmm, computeStats } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '16px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '16px',
  },
  title: {
    flex: '1 1 100%',
    marginBottom: '20px',
  },
}));


const Analytics = ({ isFetching, countries }) => {
  const classes = useStyles();

  const [stats, setStats] = useState({});

  useEffect(() => {
    if (!isFetching && countries.length > 0) {
      setStats(computeStats({ countries }));
    }
  }, [isFetching, countries]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <div>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Country Statistics
        </Typography>
      </div>
        <Grid>
          {!stats.minArea ? <Skeleton><Card title='Smallest area' content="" /></Skeleton> : <Card preTitle='Country with the' title='Smallest area' content={<Col><span>{stats.minArea.name}</span><span style={{ fontSize: '12px' }}>{stats.minArea.area ? sqkmToSmm(stats.minArea.area) : '(No Area Data)' }</span></Col>} href="https://en.wikipedia.org/wiki/List_of_countries_and_dependencies_by_area" />}
          {!stats.popAvg ? <Skeleton><Card title='Population average' content="" /></Skeleton> : <Card preTitle='For all countries' title='Population average' content={`${stats.popAvg} (Million)`} href="https://en.wikipedia.org/wiki/List_of_countries_by_population" />}
          {!stats.maxArea ? <Skeleton><Card title='Largest area' content="" /></Skeleton> : <Card preTitle='Country with the' title='Largest area' content={<Col><span>{stats.maxArea.name}</span><span style={{ fontSize: '12px' }}>{`${sqkmToSmm(stats.maxArea.area)} (Area in mi²)`}</span></Col>} href="https://en.wikipedia.org/wiki/List_of_countries_and_dependencies_by_area" />}
        </Grid>
      </Paper>
    </div>
  );
};

Analytics.propTypes = {
  countries: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default Analytics;




const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr))};
  gap: 4rem 4rem;

  justify-items: center;
  align-items: center;
  margin: 0 auto;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
