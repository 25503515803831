

/**
 * Convert square km to square metric miles
 * @param {*} value - area in square km
 * @returns the area in square metric miles, without decimals
 */
export const sqkmToSmm = (value) => Math.trunc(value * 0.386102);


/**
 * Convert a base 10 number to millions with one significant digit.
 * @param {*} value - the population in base 10
 * @returns the population in millions with one decimal (example, for Norway “5.2”)
 */
export const toMill = (value) => (Math.abs(Number(value)) / 1.0e+6).toFixed(1);

/**
 * Computes statistics on the countries data set.
 * @param {*} countries - An array of country objects
 * @returns A statistics object with the population average for all countries
 * as well as the countries with the min and max area.
 */
export const computeStats = ({ countries }) => {

  const popAvg = toMill((countries.reduce((sum, country) => sum + country.population, 0) / countries.length).toFixed(2));
  const minArea = countries.reduce((prev, curr) => (prev.area < curr.area ? prev : curr));
  const maxArea = countries.reduce((prev, curr) => (prev.area > curr.area ? prev : curr));

  return {
    popAvg,
    minArea,
    maxArea
  };
};


/**
 * Map country dataset for one (language) to many (countries)
 * @param {*} countries - An array of country objects
 * @returns An array of unique languages (objects) with an array of countries (objects)
 */
export const getLanguagesAndCountries = ({ countries }) => {

  const languages = [...new Set((countries.map(country => country.languages.map(lang => lang.iso639_1))).flat())];

  return languages.map(lang => {
    let cnts = [];

    let language;

    countries.forEach(country => {
      if (country.languages.some(cl => cl.iso639_1 === lang)) {
        language = country.languages.find(cl => cl.iso639_1 === lang);
        cnts.push(country);
      }
    });

    return {
      language,
      countries: cnts,
    };
  });
};
