import styled, { createGlobalStyle } from 'styled-components';
import modernNormalize from 'styled-modern-normalize';

export const GlobalStyles = createGlobalStyle`
${modernNormalize}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
}
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
`;
