import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';



const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  }
});

export default function SimpleCard({ title, content, preTitle=null, href='' }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        { preTitle &&
          (
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {preTitle}
            </Typography>
          )
        }
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom component="div">
          {content}
        </Typography>
      </CardContent>
      <CardActions>
        <Link href={href} target="_blank" rel="noreferrer">
          <Button size="small">Learn More</Button>
        </Link>
      </CardActions>
    </Card>
  );
};
