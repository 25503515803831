import { useState, useEffect, useRef } from "react";


export const useFetch = (url) => {
  const cache = useRef({});

  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      setIsFetching(true);

      if (cache.current[url]) {
        setData(cache.current[url]);
        setIsFetching(false);
      } else {
        const response = await fetch(url);
        const data = await response.json();
        cache.current[url] = data;
  
        setData(data);
        setIsFetching(false);
      }
    };

    fetchData();

  }, [url]);

  return { isFetching, data };
};
