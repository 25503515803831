import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import TableCell from '@material-ui/core/TableCell';

import VerticalTabs, { TabPanel } from './common/VerticalTabs';
import Table from './common/Table';
import Analytics from './Analytics';

import {
  sqkmToSmm,
  toMill,
  getLanguagesAndCountries,
} from '../helpers';



const TabContainer = ({ isFetching, countries }) => {

  const countryHeadCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Country' },
    { id: 'region', numeric: false, disablePadding: false, label: 'Region' },
    { id: 'area', numeric: true, disablePadding: false, label: 'Area (mi²)', align: "right" },
    { id: 'population', numeric: true, disablePadding: false, label: 'Population (Million)', align: "right" },
  ];

  const languageHeadCells = [
    { id: 'language', numeric: false, disablePadding: true, label: 'Language' },
    { id: 'countries', numeric: false, disablePadding: false, label: 'Countries' },
    { id: 'population', numeric: true, disablePadding: false, label: 'Combined Population (Million)', align: "right" },
  ];

  const [languageMappings, setLanguageMappings] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!isFetching && countries.length > 0) {
      setLanguageMappings(getLanguagesAndCountries({ countries }));
    }
  }, [isFetching, countries]);

  const onTabChange = (event, newValue) => setActiveTab(newValue);

  const createCountryRows = () => {

    return countries.map((country, idx) => {
      const { name, region, area, population } = country;

      const cells = (
        <>
          <TableCell padding="checkbox"></TableCell>
          <TableCell component="th" id={`th-${idx}`} scope="row" padding="none">
            {name}
          </TableCell>
          <TableCell >{region}</TableCell>
          <TableCell align="right">{sqkmToSmm(area)}</TableCell>
          <TableCell align="right">{toMill(population)}</TableCell>
        </>
      );

      return {
        key: name,
        cells,
        name,
        region,
        area: sqkmToSmm(area),
        population: toMill(population),
      };
    });
  };

  const createLanguageRows = () => {
    return languageMappings.map((lm, idx) => {
      const { language, countries } = lm;

      let countriesValue = '';
      countries.forEach((c, i) => countriesValue += `${c.name}${i === countries.length - 1 ? '' : ', '}`);

      const combinedPopulation = countries.reduce((sum, country) => sum + country.population, 0).toFixed(2);

      const cells = (
        <>
          <TableCell padding="checkbox"></TableCell>
          <TableCell component="th" id={`th-${idx}`} scope="row" padding="none">
            {language.name}
          </TableCell>
          <TableCell >{countriesValue}</TableCell>
          <TableCell align="right">{toMill(combinedPopulation)}</TableCell>
        </>
      );

      return {
        key: language.name,
        cells,
        language: language.name,
        countries: countriesValue,
      };
    });
  };

  const tabPanels = [
    <TabPanel key='1' value={activeTab} index={0}>
      <Table
        title="REST Countries"
        headCells={countryHeadCells}
        rows={createCountryRows()}
        loading={isFetching || countries.length < 1}
      />
      <Analytics isFetching={isFetching} countries={countries} />
    </TabPanel>,
    <TabPanel key='2' value={activeTab} index={1}>
      <Table
        title="Languages"
        headCells={languageHeadCells}
        rows={createLanguageRows()}
        loading={isFetching || countries.length < 1}
      />
    </TabPanel>
  ];

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const tabs = [
    <Tab key='1' label="Countries" {...a11yProps(0)} />,
    <Tab key='2' label="Languages" {...a11yProps(1)} />
  ];

  return (
    <Paper elevation={5}>
      <VerticalTabs
        tabs={tabs}
        tabPanels={tabPanels}
        value={activeTab}
        onChange={onTabChange}
      />
    </Paper>
  )
}

TabContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
};

export default TabContainer;
