import Header from './Header';
import TabContainer from './TabContainer';

import { useFetch } from '../api';
import { APIS } from '../constants';
import { GlobalStyles, AppContainer } from '../styles';



const App = () => {

  const { isFetching, data } = useFetch(APIS.REST_COUNTRIES);

  return (
    <AppContainer>
      <GlobalStyles />
      <Header title="Porterbuddy Technical Case" />
      <TabContainer isFetching={isFetching} countries={data} />
    </AppContainer>
  );
};

export default App;
